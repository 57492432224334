import { useRef } from "react";
import { Container } from "@mui/material";

import Header from "./header";
import Featured from "./featured";
import Brands from "./brands";
import Footer from "./footer";

function Landing() {
  const brandsRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);

  return (
    <Container maxWidth="xl" disableGutters sx={{ flexGrow: 1 }}>
      <Header brandsRef={brandsRef} contactRef={contactRef} />
      <Featured />
      <Brands brandsRef={brandsRef} />
      <Footer contactRef={contactRef} />
    </Container>
  );
}

export default Landing;
