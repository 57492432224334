import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Hidden,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";

import uniLogoSvg from "./uni_logo.svg";
import { HeaderProps } from "..";
import { FC } from "react";

const Bar: FC<HeaderProps> = ({ brandsRef, contactRef }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
  });

  const handleScrollToBrands = () => {
    brandsRef.current?.scrollIntoView({
      behavior: "smooth",
    } as ScrollIntoViewOptions);
  };
  const handleScrollToContact = () => {
    contactRef.current?.scrollIntoView({
      behavior: "smooth",
    } as ScrollIntoViewOptions);
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: trigger ? "primary" : "transparent",

        "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)":
          {
            backgroundColor: trigger ? "rgba(0,0,0,0.8)" : "transparent",
            // backgroundColor: trigger ? "primary.main" : "transparent",
            WebkitBackdropFilter: trigger ? "blur(80px)" : "blur(0px)",
            backdropFilter: trigger ? "blur(80px)" : "blur(0px)",
          },

        transition: "background-color 0.3s ease, backdrop-filter 0.3s ease",
      }}
    >
      <Container disableGutters maxWidth="xl">
        <Toolbar
          sx={{
            mt: trigger ? 0 : 3,
            transition: "margin 0.3s ease",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent={{ xs: "center", md: "flex-start" }}
            alignItems="center"
          >
            <CardMedia
              component="div"
              sx={{
                borderRadius: 0,
                height: { xs: 28, sm: 31, md: 32 },
                width: 220,
                backgroundSize: "contain",
                backgroundPosition: { xs: "center", md: "left center" },
                backgroundRepeat: "no-repeat",
                // backgroundColor: "red",
              }}
              image={uniLogoSvg}
            />
          </Grid>

          <Box sx={{ marginLeft: "auto" }} />
          {/* This creates a flexible space to push the Button to the right */}

          <Hidden mdDown>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}

              // sx={{
              //   mr: 3,
              // }}
            >
              <Grid item>
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  disableElevation
                  onClick={handleScrollToBrands}
                >
                  Brands
                </Button>
              </Grid>
              {/* <Grid item>
              <Button
                variant="text"
                color="secondary"
                size="medium"
                disableElevation
              >
                About Us
              </Button>
            </Grid> */}

              <Grid item>
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  disableElevation
                  onClick={handleScrollToContact}
                >
                  Contact
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  disableElevation
                >
                  Македонски
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Bar;
