import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import ugp3 from "./gy_ugp3.png";
import ws5 from "./du_ws5.png";
import ug9p from "./gy_ug9p.png";

function Featured() {
  return (
    <Container
      maxWidth="xl"
      sx={{ flexGrow: 1, py: 5, backgroundColor: grey[200] }}
    >
      <Typography
        gutterBottom
        variant="h5"
        component="h2"
        sx={{
          fontWeight: 700,
          fontStyle: "normal",
          color: grey[900],
          ml: -0.35,
          mb: 4,
          fontSize: {
            xs: "1.6rem",
            sm: "1.9rem",
            md: "2.125rem",
          },
        }}
      >
        SEASONAL FEATURES
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent={{ xs: "flex-start", md: "center" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={0}>
            <CardMedia
              sx={{
                height: 290,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // backgroundColor: "#00309D",
                // aspectRatio: "10 / 7",
              }}
              image={ugp3}
              title="Goodyear UltraGrip Performance 3"
            />
            <CardContent>
              {/* <Chip label="1st Place 2023" /> */}

              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: 500, pl: 0.3 }}
              >
                UltraGrip Performance 3
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    label="Winner 2023"
                    color="primary"
                    avatar={<Avatar>1st</Avatar>}
                  />
                </Grid>
                <Grid item>
                  <Chip label="M+S" />
                </Grid>
                <Grid item>
                  <Chip label="3PMSF" />
                </Grid>
              </Grid>

              <Accordion elevation={0} sx={{ mt: 3, mb: -2 }}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={"↓"}
                >
                  Results
                </AccordionSummary>
                <AccordionDetails>
                  <List dense={true} sx={{ pb: 0 }}>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="1st place Excellent"
                        secondary="225/40 R18, Auto Bild 2023"
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="2nd place Very good"
                        secondary="255/45 R20, Auto, Motor und Sport 2023"
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={0}>
            <CardMedia
              sx={{
                height: 290,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // backgroundColor: "#00309D",
                // aspectRatio: "10 / 71",
              }}
              image={ws5}
              title="Dunlop Winter Sport 5"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: 500, pl: 0.3 }}
              >
                Winter Sport 5
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    label="Winner 2023"
                    color="primary"
                    avatar={<Avatar>1st</Avatar>}
                  />
                </Grid>
                <Grid item>
                  <Chip label="M+S" />
                </Grid>
                <Grid item>
                  <Chip label="3PMSF" />
                </Grid>
              </Grid>

              <Accordion elevation={0} sx={{ mt: 3, mb: -2 }}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={"↓"}
                >
                  Results
                </AccordionSummary>
                <AccordionDetails>
                  <List dense={true} sx={{ pb: 0 }}>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="1st place Highly recommended"
                        secondary="205/60 R16, TCS 2023"
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="1st place Highly recommended"
                        secondary="205/60 R16, ÖAMTC 2023"
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="1st place Good"
                        secondary="205/60 R16, ADAC 2023"
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={0}>
            <CardMedia
              sx={{
                height: 290,
                backgroundSize: "cover",
                backgroundPosition: "center 100%",
                backgroundRepeat: "no-repeat",
              }}
              image={ug9p}
              title="Goodyear UltraGrip 9+"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: 500, pl: 0.3 }}
              >
                UltraGrip 9+
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    label="Winner 2022"
                    color="primary"
                    avatar={<Avatar>1st</Avatar>}
                  />
                </Grid>
                <Grid item>
                  <Chip label="M+S" />
                </Grid>
                <Grid item>
                  <Chip label="3PMSF" />
                </Grid>
              </Grid>

              <Accordion elevation={0} sx={{ mt: 3, mb: -2 }}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={"↓"}
                >
                  Results
                </AccordionSummary>
                <AccordionDetails>
                  <List dense={true} sx={{ pb: 0 }}>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{ mb: 0 }}
                        primary="1st place Good"
                        secondary="185/65 R15, ADAC 2022"
                      />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Featured;

// <CardMedia
// component="div"
// sx={{
//   borderRadius: 1,
//   height: { md: logoHeightMd, lg: logoHeightLg },
//   backgroundSize: "cover",
//   backgroundPosition: "center",
//   backgroundRepeat: "no-repeat",
//   backgroundColor: "#00309D",
//   aspectRatio: "339 / 71",
// }}
// image={gyLogo}
// title="Goodyear Logo"
// />
