import { CardMedia, Container, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import gyLogo from "./goodyear.svg";
import duLogo from "./dunlop.svg";
import fuLogo from "./fulda.svg";
import saLogo from "./sava.svg";
import deLogo from "./debica.svg";
import piLogo from "./pirelli.svg";
import { FC } from "react";

const logoHeightXs = 50;
const logoHeightMd = 55;
const logoHeightLg = 60;

interface BrandsProps {
  brandsRef: React.MutableRefObject<HTMLDivElement | null>;
}

const Brands: FC<BrandsProps> = ({ brandsRef }) => {
  return (
    <Container
      ref={brandsRef}
      maxWidth="xl"
      sx={{ flexGrow: 1, mt: 2, mb: 4, py: 3 }}
    >
      <Typography
        variant="h4"
        component="h2"
        // color="primary"

        sx={{
          fontWeight: 700,
          fontStyle: "normal",
          ml: -0.35,
          // mb: 4,
          color: grey[900],
          fontSize: {
            xs: "1.6rem",
            sm: "1.9rem",
            md: "2.125rem",
          },
        }}
      >
        BRANDS WE CARRY
      </Typography>

      <Typography
        gutterBottom
        variant="body1"
        component="h2"
        // color="primary"
        sx={{
          mb: 4,
          color: grey[900],
        }}
      >
        Official tire distributor for Goodyear Group, including brands:
        Goodyear, Dunlop, Fulda, Sava, Debica, Kelly Tires, and Diplomat
        (exclusive); and Pirelli.
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // spacing={2}
        columnSpacing={5}
        rowSpacing={3}
        // sx={{ mt: 2 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#00309D",
              aspectRatio: "339 / 71",
            }}
            image={gyLogo}
            title="Goodyear Logo"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#FFD100",
              aspectRatio: "314 / 71",
            }}
            image={duLogo}
            title="Dunlop Logo"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#ffffff",
              aspectRatio: "283 / 71",
              boxShadow: "inset 0px 0px 0px 1.5px " + grey[200],
            }}
            image={fuLogo}
            title="Fulda Logo"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#FFD100",
              aspectRatio: "232 / 71",
            }}
            image={piLogo}
            title="Pirelli Logo"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#43B649",
              aspectRatio: "178 / 71",
            }}
            image={saLogo}
            title="Sava Logo"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <CardMedia
            component="div"
            sx={{
              borderRadius: 1,
              height: { xs: logoHeightXs, md: logoHeightMd, lg: logoHeightLg },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#FFC629",
              aspectRatio: "260 / 71",
            }}
            image={deLogo}
            title="Debica Logo"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Brands;
