import { createTheme } from "@mui/material/styles";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/300-italic.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/500-italic.css";
// import '@fontsource/rubik/600-italic.css';
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/700-italic.css";

// import '@fontsource/rubik/800-italic.css';
// import '@fontsource/rubik/900-italic.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#001689",
    },
    secondary: {
      main: "#8CC63F",
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: "'Rubik', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // minWidth: "1000px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontStyle: "normal",
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: "#ffffff",
          },
        },
      ],
    },
  },
});

export default theme;
