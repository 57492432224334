import {
  Button,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import emailSvg from "./email.svg";
import { FC } from "react";
import { grey } from "@mui/material/colors";

interface FooterProps {
  contactRef: React.MutableRefObject<HTMLDivElement | null>;
}

const Footer: FC<FooterProps> = ({ contactRef }) => {
  return (
    <Container
      ref={contactRef}
      component="footer"
      maxWidth="xl"
      sx={{
        flexGrow: 1,
        py: 5,
        pb: 5,
        backgroundColor: grey[900],
        fontSize: {
          xs: "1.6rem",
          sm: "1.9rem",
          md: "2.125rem",
        },
      }}
    >
      <Typography
        gutterBottom
        variant="h4"
        component="h2"
        sx={{
          fontWeight: 700,
          fontStyle: "normal",
          color: "white",
          mb: 3,
          ml: -0.35,
          fontSize: {
            xs: "1.6rem",
            sm: "1.9rem",
            md: "2.125rem",
          },
        }}
      >
        CONTACT US
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={3}
        columnSpacing={5}
      >
        <Grid item xs={12} md={12}>
          <List dense={true} sx={{ color: "white" }} disablePadding>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}
                  >
                    UNI-ROJAL KOMPANI DOOEL
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    REG: 5299942
                    <br />
                    VAT: MK4021998126195
                  </>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    E-mail:{" "}
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled
                      size="small"
                    >
                      <CardMedia
                        component="div"
                        sx={{
                          my: 1,
                          height: 13,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          aspectRatio: "184 / 13",
                        }}
                        image={emailSvg}
                      />
                    </Button>
                  </>
                }
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <List dense={true} sx={{ color: "white" }} disablePadding>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1rem",
                      textTransform: "uppercase",
                      color: "secondary.main",
                    }}
                  >
                    Office Skopje
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    Ul. 2 Br. 84, Ilinden
                    <br />
                    1000, Skopje (MK)
                  </>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary={"Tel: +389 (0)2 5 300 700"} />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <List dense={true} sx={{ color: "white" }} disablePadding>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1rem",
                      textTransform: "uppercase",
                      color: "secondary.main",
                    }}
                  >
                    Office Prilep
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    Ul. Aleksandar Mak. BB
                    <br />
                    7500, Prilep (MK)
                  </>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary={"Tel: +389 (0)48 413 389"} />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <List dense={true} sx={{ color: "white" }} disablePadding>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "1rem",
                      textTransform: "uppercase",
                      color: "secondary.main",
                    }}
                  >
                    Roadside Assistance
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="white">
                    Memeber of Goodyear ServiceLine24
                  </Typography>
                }
              />
            </ListItem>

            <ListItem disableGutters disablePadding>
              <ListItemText primary={"Toll-free 24/7: 0800 88526"} />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        columnSpacing={2}
      >
        <Grid item>
          <Typography
            // variant="h6"
            variant="body2"
            sx={{
              //   fontSize: "1rem",
              textTransform: "uppercase",
              color: "white",
              pt: 10,
            }}
          >
            ©{new Date().getFullYear()} UNI-ROJAL KO. DOOEL
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;

/* <Grid item>
          <List dense={true} sx={{ color: "white" }}>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1rem", textTransform: "uppercase" }}
                  >
                    Vulco Prilep
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    Ul. Aleksandar Mak. Br. 428,
                    <br />
                    7500, Prilep (MK)
                  </>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary={
                  <>
                    Toll-free Roadside Assistance 24/7:
                    <br />
                    0800 VULCO (88526)
                  </>
                }
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText primary={"Tel: +389 (0)48 410 164"} />
            </ListItem>
          </List>
        </Grid> */
