import { FC, Fragment, MutableRefObject } from "react";

import { CardMedia, Container, Typography } from "@mui/material";

import Bar from "./bar";

import uniFlagSvg from "./uni_flag.svg";
// import uniFlagPng from "./uni_flag.png";

export interface HeaderProps {
  brandsRef: MutableRefObject<HTMLDivElement | null>;
  contactRef: MutableRefObject<HTMLDivElement | null>;
}

const Header: FC<HeaderProps> = ({ brandsRef, contactRef }) => {
  return (
    <Fragment>
      <Bar brandsRef={brandsRef} contactRef={contactRef} />
      <CardMedia
        component="header"
        sx={{
          // To make the child sit on the bottom
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          mt: { xs: -11, md: -11 },
          borderRadius: 0,
          height: { xs: 310, sm: 370, md: 400 },
          backgroundColor: "#001689",
          backgroundSize: {
            xs: "110%",
            sm: "105%",
            md: "80%",
            lg: "81%",
            xl: "75%",
          },
          backgroundPosition: {
            xs: "-10% -4%",
            sm: "1% 8%",
            md: "110% 5%",
            lg: "100% 10%",
            xl: "90% 10%",
          },
          backgroundRepeat: "no-repeat",
        }}
        image={uniFlagSvg}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2.6rem", sm: "3.7rem", md: "4.5rem" },
              fontStyle: "italic",
              textTransform: "uppercase",
              color: "white",
              mb: 1,
              pl: { xd: 0, md: "10%", lg: "6%", xl: 0 },
            }}
          >
            Wholesale <br />
            Tire Distributor
          </Typography>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 300,
              fontStyle: "italic",
              textTransform: "uppercase",
              color: "white",
              // ml: "8.3vw",
              fontSize: { xs: "2.2rem", sm: "2.9rem", md: "3rem" },
              pl: { xd: 0, md: "10%", lg: "6%", xl: 0 },
              ml: 0.5,
              mb: 4,
            }}
          >
            for Macedonia
          </Typography>
        </Container>
      </CardMedia>
    </Fragment>
  );
};

export default Header;
